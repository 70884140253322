import tpsApiInstance from "./tpsApiBase";
import {
  ICorporationList,
  ICorporationDetail,
  ICorporationGetListTotalNumRequestParams,
  ICorporationGetListRequestParams,
  ICorporationGetDetailRequestParams,
  ICorporationDetailUpdateRequestParams,
  ICorporationCreateResponseSchema,
  ICorporationDetailUpdateResponseSchema
} from './models/corporation';

const CORPORATION_PATH = "/listCardIssuer";
const CORPORATION_GET = "/getCardIssuer";
const CORPORATION_UPDATE = "/updateCardIssuer";
const CORPORATION_CREATE = "/createCardIssuer";

export const apiCorporationGetListTotalNum = async(
  pData?: ICorporationGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total";
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  console.log("query:",query);
  const res = await tpsApiInstance.get(`${CORPORATION_PATH}${query}`);
  console.log("total:",res?.data[0]?.total);
  return res?.data[0]?.total;
};

export const apiCorporationGetList = async(
  pData: ICorporationGetListRequestParams): Promise<ICorporationList | Blob> => {  
  let query: string = "?";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${CORPORATION_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${CORPORATION_PATH}${query}`);
    return res?.data as ICorporationList;
  }
};

export const apiCorporationGetDetail = async (
  pData: ICorporationGetDetailRequestParams): Promise<ICorporationDetail> => {
    const res = await tpsApiInstance.get(`${CORPORATION_GET}?brand_id=${pData.id}`);
    console.log(res.data);
    return res?.data[0] as ICorporationDetail;
};

export const apiCorporationCreate = async (
  pData: ICorporationDetail): Promise<ICorporationCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(CORPORATION_CREATE, pData);
  return res?.data as ICorporationCreateResponseSchema;
};

export const apiCorporationDetailUpdate = async (
  pData: ICorporationDetailUpdateRequestParams
  ): Promise<ICorporationDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData.data));
  const res = await tpsApiInstance.post(`${CORPORATION_UPDATE}?brand_id=${pData.id}`, pData.data);
  return res?.data;
};

