
import { Options, Vue } from 'vue-class-component';
import { apiCorporationGetList, 
         apiCorporationGetListTotalNum } from '../services/tpsApi/tpsApiCorporation';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { ICorporationList } from '../services/tpsApi/models/corporation';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import CorporationForm from '@/components/CorporationForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    ActionMenu,
    Table,
    CorporationForm,
    Pagination
  }
})

export default class Corporation extends Vue {
    $refs!: {
        form: CorporationForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        brand_id: "品牌名稱",
        easycard_vid: "悠遊卡業者代碼",
        ipass_vid: "一卡通業者代碼",
        icash_vid: "愛金卡業者代碼",
    };

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    corporationList: ICorporationList | Blob = [];
    filter: string | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiCorporationGetListTotalNum({filter: this.filter});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType, pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter});
        this.corporationList = await apiCorporationGetList(pData);
    } 

    async download(pData: { output: string }) {
        const res = await apiCorporationGetList({output: pData.output, filter: this.filter});
        exportFile({ fileName: "票證公司", data: res });
    }

    doSearch(pData: {filter: string}) {
        this.filter = pData.filter;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
