
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiCorporationGetDetail,
         apiCorporationDetailUpdate,
         apiCorporationCreate } from '../services/tpsApi/tpsApiCorporation';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { ICorporationDetail } from '../services/tpsApi/models/corporation';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
      WarningModal
  },
  emits: ["hide-form"]
})


export default class CorporationForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: ICorporationDetail = {} as any;
    formData: ICorporationDetail = {} as any;
    actionType: string = "";
    corporationID: number = -1;
    brandSelectOptions: {} = {};
    brandWarningText: string = "";

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }

    async mounted() {
        this.brandSelectOptions = await apiBrandGetListName();
    }

    async initForm(pActionType, pCorporationID) {
        this.actionType = pActionType;
        this.corporationID = pCorporationID;
        if(pCorporationID) {
            this.formData = Object.assign({}, await apiCorporationGetDetail({id:pCorporationID}));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Field "brand_id" default select first item
            this.formData['brand_id'] = Number(Object.keys(this.brandSelectOptions)[0]);
        }
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            if(this.actionType == "update") {
                let res = await apiCorporationDetailUpdate({id: this.corporationID, 
                                                            data: arrangeUpdateData(this.formData,this.originalData)});
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }      
            else if(this.actionType == "create") {
                try{
                    let res = await apiCorporationCreate(this.formData);
                    if(res.msg === ("ok")) this.hideForm(true);
                    else this.hideForm(false);
                }
                catch(err) {
                    if(err?.data?.msg == "existing record") {
                        this.brandWarningText = "此品牌資料已存在";
                    }
                }
               
            }    
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
